<template>
    <modal-lateral ref="modalNotificaciones" titulo="Notificaciones">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 60px)">
            <div class="text-general2 bg-light py-2 px-2">
                Recientes
            </div>
            <div v-for="(msg, m) in mensajes" :key="m" class="row mx-0 px-2 py-3 " :class="msg.estado == 1 ||msg.estado == 2 || msg.estado == 4 ? 'bg-dark-blue' : ''">
                <div :class="`rounded-circle ${msg.estado == 2 || msg.estado == 4 ? 'text-red border-red' : 'border-black'} d-middle j-center`" style="width:40px;height:40px;">
                    <i :class="`icon-${iconosMensaje(msg.estado)} f-20`" />
                </div>
                <div class="col">
                    <p class="text-general2 f-600 f-14" v-text="`${msg.titulo} - ID ${msg.id}`" />
                    <p class="text-general f-14"> 
                        <b class="text-general2">Carlos</b> 
                        confirmó tu pedido
                    </p>
                    <p class="text-general2 f-12">{{ msg.hora }}</p>
                </div>
            </div>
            <div class="text-general2 bg-light py-2 px-2">
                Recientes
            </div>
            <div v-for="(msg, m) in mensajes" :key="m" class="row mx-0 px-2 py-3 " :class="msg.estado == 1 ||msg.estado == 2 || msg.estado == 4 ? 'bg-dark-blue' : ''">
                <div :class="`rounded-circle ${msg.estado == 2 || msg.estado == 4 ? 'text-red border-red' : 'border-black'} d-middle j-center`" style="width:40px;height:40px;">
                    <i :class="`icon-${iconosMensaje(msg.estado)} f-20`" />
                </div>
                <div class="col">
                    <p class="text-general2 f-600 f-14" v-text="`${msg.titulo} - ID ${msg.id}`" />
                    <p class="text-general f-14"> 
                        <b class="text-general2">Carlos</b> 
                        confirmó tu pedido
                    </p>
                    <p class="text-general2 f-12">{{ msg.hora }}</p>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            mensajes: [
                {
                    id: 1574823,
                    titulo: 'Nuevo pedido',
                    estado: 1,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 2546854,
                    titulo: 'Pedido Editado',
                    estado: 2,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 32546894,
                    titulo: 'Tu pedido va en camino',
                    estado: 3,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 14582523,
                    titulo: 'Han cancelado el pedido',
                    estado: 4,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 65254412,
                    titulo: 'Tu pedido ha sido entregado',
                    estado: 5,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 65254412,
                    titulo: 'Tienes un nuevo mensaje',
                    estado: 6,
                    hora: 'Hace 2 horas'
                },
                {
                    id: 26451321,
                    titulo: 'Han rechazado tu',
                    estado: 7,
                    hora: 'Hace 2 horas'
                }
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalNotificaciones.toggle();
        },
        iconosMensaje(state){
            switch (state){
            case 1:
                return 'check-circle';
            case 2:
                return 'playlist-edit';
            case 3:
                return 'bike';
            case 4:
                return 'cancel-circled-outline';
            case 5:
                return 'shopping';
            case 6:
                return 'message-text-outline';
            case 7:
                return 'close-circle';
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-dark-blue{
    background-color: #F2F9FD;
}
</style>